<template>
  <base-layout-two
    page-title="Room"
    page-default-back-link="/rooms"
    end-button-text="Edit"
    :end-button-url="`/rooms/${$route.params.id}/edit`"
  >
    <section class="ion-padding background-white">
      <h1>Room {{ room.number }} {{ (room.flex) ? "(flex)" : ""}}</h1>
    </section>

    <div class="padding-8">
      <base-card title="Room Details">
        <base-field-display label-text="Room Number">
          <p>{{ room.number }}</p>
        </base-field-display>

        <base-field-display label-text="Property">
          <p>{{ room.property.name }}</p>
        </base-field-display>

        <base-field-display label-text="Room Type">
          <p>{{ room.room_type.name }}</p>
        </base-field-display>

        <base-field-display label-text="Flex Room">
          <p>{{ (room.flex) ? "Yes" : "No"}}</p>
        </base-field-display>
      </base-card>
    </div>
  </base-layout-two>
</template>

<script>
export default {
  data() {
    return {
      room: {
        property: {},
        room_type: {},
      },
    };
  },

  ionViewWillEnter() {
    this.fetchRoom();
  },

  methods: {
    async fetchRoom() {
      await this.axios
        .get(`${process.env.VUE_APP_API}/rooms/${this.$route.params.id}`)
        .then((response) => {
          this.room = response.data.room;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>